import { stringify, parse } from "zipson";

export const storageKeys = {
  account: "account",
  password: "password",
  isRememberMe: "isRememberMe",
  mockLiveUrl: "mockLiveUrl",
};

function encodeUnicode(str) {
  // First we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return window.btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_match, p1) => {
      return String.fromCharCode(`0x${p1}`);
    })
  );
}

function decodeUnicode(str) {
  // Going backward: from byte-stream to percent-encoding, to original string.
  return decodeURIComponent(
    window
      .atob(str)
      .split("")
      .map((c) => {
        const p = `00${c.charCodeAt(0).toString(16)}`;
        return `%${p.slice(-2)}`;
      })
      .join("")
  );
}

export const encode = (data) => {
  if (!data) {
    return;
  }
  return stringify(encodeUnicode(data));
};

export const decode = (data) => {
  if (!data) {
    return;
  }
  return decodeUnicode(parse(data));
};

export const saveData = (key, data) => {
  if (!key || !data) {
    return;
  }
  const encodedData = encode(data);
  const encodedKey = encode(key);
  localStorage.setItem(encodedKey, encodedData);
};

export const loadData = (key, defaultValue) => {
  const encodedKey = encode(key);
  const encodedData = localStorage.getItem(encodedKey);
  if (!encodedData) return defaultValue;
  const data = decode(encodedData);
  return data;
};

export const removeData = (key) => {
  const encodedKey = encode(key);
  localStorage.removeItem(encodedKey);
};

<template>
  <div class="main-container">
    <div class="course-nav">
      <router-link :to="{name:'Exercise'}">章节练习</router-link>
      <span class="slash">></span>
      <span class="current">{{exercise.name}}</span>
    </div>
    <div style="position:relative;">
      <div class="course-left">
        <div class="course-info">
          <div class="course_ccover">
            <img :src="exercise.indexpic">
          </div>
          <div class="course-detail">
            <div class="clear sec-top">
              <div class="clear course-info-title">

                <span class="course-title f-left">{{exercise.name}}</span>
              </div>
              <div class="question-count">
                <div class="question-total-count">
                  总题量：{{exercise.question_num}}
                </div>
                <div class="question-type-count">
                  <span v-show="exercise.question_type_num.single_choice > 0">单选：{{exercise.question_type_num.single_choice || 0}}</span>
                  <span v-show="exercise.question_type_num.multi_choice > 0">多选：{{exercise.question_type_num.multi_choice || 0}}</span>
                  <span v-show="exercise.question_type_num.judge > 0">判断：{{exercise.question_type_num.judge || 0}}</span>
                  <span v-show="exercise.question_type_num.subject > 0">简答：{{exercise.question_type_num.subject || 0}}</span>
                </div>
                <div class="total-sale">{{ exercise.total_sales_num}}人已做题</div>
              </div>
            </div>
            <div class="clear price-container">
              <!-- <span class="f-left right-label">价格：</span> -->
              <span class="price free"
                    v-if="exercise.is_free">免费</span>
              <span v-else>
                <span class="price">
                  <Currency class="currency-mini"></Currency>
                  <span>{{exercise.price|price}}</span>
                </span>
                <strike v-if="exercise.underlined_price"
                        style="margin-left:10px;">
                  <Currency class="currency-mini"></Currency>
                  <span>{{exercise.underlined_price|price}}</span>
                </strike>
              </span>
            </div>
            <div class="study">
              <div v-if="exercise.is_buy > 0">
                <button @click="goToPlay()"
                   class="btn btn-disabled" disabled>选择章节开始做题</button>
                <a @click="goToCourse()" v-if="exercise.related_course_id > 0 && !exercise.is_buy_course"
                   class="btn consultation">购买对应课程</a>
              </div>
              <div v-else>
                <a v-if="exercise.is_free" @click="buy()"
                   class="btn btn-primary">免费领取</a>
                <a v-else @click="buy()"
                    class="btn btn-primary">立即购买</a>
                <a @click="goToCourse()" v-if="exercise.related_course_id > 0 && !exercise.is_buy_course"
                   class="btn consultation">购买对应课程</a>
                <div class="tips" v-if="exercise.related_course_id > 0">
                  <span class="red">提示:该练习为学员免费练习</span>
                  (购买对应课程即可免费获得做题权限，您也可以单独购买此练习)
                </div>
              </div>

              <div class="collect_share">
                <span class="f-right collect">
                  <span v-if="exercise.is_collect==0"
                        @click="collect">
                    <img src="@/assets/images/course/collect-none@2x.png"
                         style="width:16px;height:15px" />
                    <span style="vertical-align:middle;">收藏</span>
                  </span>
                  <span class="collect-has"
                        v-if="exercise.is_collect==1"
                        @click="cancelCollect">
                    <img src="@/assets/images/course/collect@3x.png"
                         style="width:14px;height:15px;" />
                    <span>已收藏</span>
                  </span>
                </span>
                <div class="bdsharebuttonbox f-right share"
                     @click="shareCourse">
                  <a href="javascript:void(0)"
                     class="bds_more"
                     data-cmd="more"
                     style="background-size:14px 14px;background-repeat:no-repeat;"
                     :style="{backgroundImage:'url('+share+')'}">
                    <span>分享</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="course-main f-left">
          <ul id="nav"
              class="clear tabs"
              :style="leftFixed?'position:fixed;left:'+leftFixedPosition+'px;top:84px;':''">
            <li class="tab-item"
                :class="{'tab-item-current':tab==0}"
                @click="changeTab(0)">
              <span>章节列表</span>
              <div class="tab-line"></div>
            </li>
            <li v-if="exercise.course_type!=9"
                class="tab-item"
                :class="{'tab-item-current':tab==1}"
                @click="changeTab(1)">
              <span>评价（{{comments.length}}）</span>
              <div class="tab-line"></div>
            </li>
          </ul>
          <div class="tab-content">
            <div id="course-chapter"
                 class="tab-content-plan"
                 v-show="tab==0">
                 <div class="exercise-chapter">
                   <ul class="parent-chapters">
                     <li class="parent-chapter-item" v-for="(item,key) in chapters" :key="key">
                       <div class="clearfix">
                           <div class="parent-chapter-title" @click="toggleExpands(item.id)">
                             <i v-if="chapterExpand[item.id]>0" class="el-icon-caret-bottom"></i>
                             <i v-else class="el-icon-caret-right"></i>
                              {{ item.title }}</div>

                           <div class="chapter-count">共{{item.question_count}}道</div>

                       </div>
                       <ul class="child-chapters" :class=" {'expand' : chapterExpand[item.id]==1 }">
                         <li class="child-chapter-item" v-for="(i,k) in item.children" :key="k" @click="goToExam(i.id)">
                           <div class="clearfix">
                             <div class="child-chapter-title">{{ i.title }}</div>
                             <div class="chapter-count">{{i.question_num}}道</div>
                           </div>
                         </li>
                       </ul>
                     </li>
                   </ul>
                 </div>
            </div>
            <div v-show="tab==1"
                 class="tab-content-comment">
              <div class="comment-item"
                   v-for="comment in comments"
                   :key="comment.id">
                <div class="header">
                  <img class="avatar"
                       :src="comment.avatar" />
                  <span class="nickname">{{comment.nickname}}</span>
                  <div style="position:relative;">
                    <el-rate v-model="comment.grade"></el-rate>
                    <div style="position:absolute;left:0;top:0;right:0;bottom:0;z-index:1;"></div>
                  </div>
                </div>
                <div class="content">{{comment.content}}</div>
                <div class="time">{{new Date(parseInt(comment.created_at)*1000).format("yyyy-MM-dd hh:mm")}}</div>
              </div>
              <Empty v-if="comments.length==0"
                     title="暂无课程评价信息哦！" />
            </div>
          </div>
        </div>
        <div class="teacher_list f-left">
          <p>推荐试题</p>
          <ul class="clear recommend"
              v-if="recommentExercise.length">
            <li class="recommend-item"
                v-for="(recommend, key) in recommentExercise"
                :key="recommend.id"
                :title="recommend.name">
                <div class="redommend-index" :class=" key == 0 && 'red' ||  ( key == 1 && 'orange') ||  ( key == 2 &&  'yellow' )">{{ key+1 }} </div>
                <div class="recommend-name">
                    <router-link :to="{name:'ExerciseShow',params:{id:recommend.id}}">{{ recommend.name }}
                  </router-link>
                </div>
            </li>
          </ul>
        </div>
      </div>
      <div style="clear:both;"></div>
    </div>
    <el-dialog title="分享"
               :visible.sync="showShareModal"
               width="400px">
      <vue-qr :text="shareUrl"
              :size="200"
              class="center"></vue-qr>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="showShareModal = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import VueQr from "vue-qr";

export default {
  name: "ExerciseShow",
  components: {
    VueQr
  },
  data () {
    return {
      share: require("@/assets/images/course/share@2x.png"),
      id: "",
      exercise: {
        question_num: 0,
        question_type_num: {
          single_choice: 0,
          multi_choice: 0,
          subject: 0,
          judge: 0
        }
      },
      chapters: [],
      recommentExercise: [],
      tab: 0,
      comments: [],
      scrollTop: 0,
      rightFixed: 0,
      rightTop: 206,
      rightFixedPosition: 0,
      rightFixedBottom: 0,
      rightHeight: 0,
      leftFixed: false,
      leftFixedPosition: 0,
      footbarTop: 0,
      showShareModal: false,
      shareUrl: "",
      show_gifts: false,
      chapterExpand: {},
    };
  },
  async created () {
    await this.getData();
  },
  watch: {
    $route: async function () {
      await this.getData();
    }
  },
  beforeCreate () {
    window._bd_share_main = "";
  },
  mounted () {
    this.$nextTick(() => {
      let $ = window.$;

      let $right = $("#course_detail_right");
      let $footer = $(".footerBar");
      let $left = $(".course-left");
      $(window)
        .scrollTop(0)
        .scroll(() => {
          this.setFixed($right, $footer, $left);
        })
        .resize(() => {
          this.setFixed($right, $footer, $left);
        });
    });
  },
  beforeDestroy () {
    window.$(window).unbind("scroll");
  },
  methods: {
    setFixed ($right, $footer, $left) {
      let $ = window.$;
      this.rightHeight = $right.height();
      if (this.rightHeight > $left.height()) {
        return;
      }
      this.scrollTop = $(window).scrollTop();
      this.rightFixedPosition = parseInt(($(window).width() - 1200) / 2);
      this.footbarTop = $footer.offset().top;
      this.setRightFixed();
    },
    setRightFixed () {
      let scrollTop = this.scrollTop;
      if (scrollTop == 0) {
        this.rightFixed = 0;
        return;
      }
      if (scrollTop + 224 + this.rightHeight >= this.footbarTop) {
        this.rightFixed = 2;
        return;
      }
      if (scrollTop + 114 > this.rightTop) {
        this.rightFixed = 1;
      } else {
        this.rightFixed = 0;
      }
    },
    async getData () {
      this.id = this.$route.params.id;
      this.tab = this.$route.query.tab || 0;
      await this.getExercise();
      await this.getComment();
    },
    async getExercise () {
      let data = await this.$http.get(
        "/api/app/exercise/" + this.id
      );
      this.exercise = data;
      document.title = this.exercise.name;
      this.requestChapter();
      this.getExercisesList();
    },
    //推荐试题，按上架顺序展示该分类下最新上架的10个练习
    async getExercisesList() {
      await this.$http.get(
        "/api/app/exercises", {
          classify_id : this.exercise.classify_id,
          excude_id: this.id,
          limit: 10
          }
      ).then(data => {
        this.recommentExercise = data.list;
      });
    },
    requestChapter () {
      this.$http
        .get("/api/app/exercise-chapter?exercise_id="+this.id)
        .then(data => {
          this.chapters = data.list;
          this.chapters.forEach(c => {
            this.chapterExpand[c.id] = 1;
            if (!c.child) {
              return;
            }
          });
        });
    },
    async collect () {
      this.exercise.collect_id = await this.$http.post(
        "/api/app/collect",
        {
          exercise_id: this.id,
          type: 5
        },
        true
      );
      this.exercise.is_collect = 1;
      await this.$success("收藏成功");
    },
    async cancelCollect () {
      await this.$http.put(
        "/api/app/collect/cancel/" + this.exercise.collect_id + "/1"
      );
      this.exercise.is_collect = 0;
      await this.$success("取消收藏成功");
    },
    changeTab (tab) {
      this.tab = tab;
    },
    async buy () {
      if (this.exercise.is_free) {
        await this.$http.post(
          "/api/app/order/downOrder",
          {
            shop_id: this.id,
            type: 15
          },
          true
        );
        this.exercise.is_buy = 1;
        await this.$success("报名成功");
        await this.getExercise();
      } else {
        this.$router.push({
          name: "OrderConfirm",
          params: { id: this.id },
          query: { type: 15 }
        });
      }
    },
    async getComment () {
      let data = await this.$http.post("/api/app/common/commentList", {
        limit: 10,
        shop_id: this.id,
        type: 6
      });
      this.comments = data.list;
    },
    async shareCourse () {
      let data = await this.$http.get(
        "/api/app/share/" + this.exercise.id,
        {type:2},
        true
      );
      this.shareUrl = data.url;
      this.showShareModal = true;
    },
    /**
     * 赠品类型
     */
    getGiftType (val) {
      return ShopType.getValue(val);
    },
    /**
     * 显示赠品弹框
     */
    getGifts () {
      this.show_gifts = true;
    },

    goToCourse () {
      this.$router.push({
        name: "CourseShow",
        params: { id: this.exercise.related_course.id },
        query: { type: this.exercise.related_course.course_type }
      });
    },
    toggleExpands(id) {
      if (this.chapterExpand[id] == 0) {
        this.chapterExpand[id] = 1;
      } else {
        this.chapterExpand[id] = 0;
      }
      this.$forceUpdate();
        console.log( this.chapterExpand );
    },
    goToExam(id) {
      if(!this.exercise.is_buy ) {
        this.$warning("请先购买此章节练习！", {
          duration: 1000
        });
        return;
      }
      this.$router.push({
        name: "ExamExerciseDo",
        params: { },
        query: { id, exercise_id: this.id}
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/course-detail.scss";
</style>
<style lang="scss">
#course-detail {
  overflow: hidden;
  img {
    max-width: 100%;
    vertical-align: bottom;
  }
}
.bdshare_popup_box {
  width: 116px !important;
}
.bdshare_popup_list li {
  display: none;
  &:nth-child(2),
  &:nth-child(5),
  &:nth-child(14) {
    display: block;
  }
}
.bdshare_popup_bottom {
  display: none;
}
.bdshare_dialog_bg,
.bdshare_dialog_box {
  display: none !important;
}
.sec-top{
  height: 120px;
}
.question-count{
  color: #999899;
  font-size: 14px;
  line-height: 20px;
  span{
    padding-right: 25px;
  }
  .total-sale{
    padding-top: 3px;
  }
}
.study{
  .tips{
    .red{
      color: #F90015;
    }
    font-size: 12px;
    line-height: 30px;
  }
  .btn{
    &.btn-disabled {
      background: #bfbfbf;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 200;
      cursor: not-allowed;
    }
  }
}
.exercise-chapter{
  margin: 20px;
  .el-icon-caret-bottom,.el-icon-caret-right{
    color: #3B90FF;
  }
  .parent-chapters{
    line-height: 50px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom:20px;
    .parent-chapter-item{
      border-bottom: 1px dashed #efefef;
      padding-bottom: 10px;
    }
    .parent-chapter-title, .child-chapter-title{
      float: left;
      cursor: pointer;
    }
    .chapter-count{
      float: right;
    }
    .child-chapters{
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      line-height: 35px;
      padding-left: 20px;
      display: none;
      &.expand {
        display: block;
      };
    }
  }
}
.recommend{
  margin: 10px 0;
  .recommend-item{
    width: 100%;
    overflow: hidden;
    padding: 0 26px;
      line-height: 38px;
    .redommend-index{
      width: 18px;
      height: 18px;
      background-color: #E7E7E7;
      text-align: center;
      color: #999;
      line-height: 19px;
      font-size: 12px;
      display: inline-block;
      &.red{
        background-color: #EF5146;
        color: #fff;
      }
      &.orange{
        background-color: #F17B49;
        color: #fff;
      }
      &.yellow{
        background-color: #F8CA52;
        color: #fff;
      }
    }
    .recommend-name{
      font-size: 14px;
      color: #333333;
      display: inline-block;
      padding-left: 10px;
    }
  }
}
</style>

<template>
  <div class="main-container">
    <div class="bread">
      <router-link :to="{ name: 'Home' }">首页</router-link>
      <span class="slash"> > </span>
      <span class="current">章节练习</span>
    </div>
    <div class="select-tab">
      <Classify
        ref="classify"
        :classify_id="classify_id"
        :all="true"
        @changeClassify="changeClassify"
      />
      <div class="item" v-for="(attrItem, index) in attrs" :key="index">
        <div class="left">
          <p>{{ attrItem.attr }}：</p>
        </div>
        <div class="right">
          <ul>
            <li
              v-for="(valueItem, index) in attrItem.value"
              :key="index"
              :class="{ active: attrCondition[attrItem.id] == valueItem.id }"
              @click="onClickAttr(attrItem.id, valueItem.id)"
            >
              {{ valueItem.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <el-card
        class="card"
        v-for="(item, index) in exercises"
        :key="index"
        shadow="hover"
        @click.native="onClickTeacher(item)"
      >
        <div class="card-content">
          <div class="card-top">
            <div class="exercise-info">
              <p class="name">{{ item.name }}</p>
              <p class="level">{{ item.level }}</p>
              <p v-if="item.related_course_id > 0" class="student-free">
                学员免费
              </p>
            </div>
            <div class="indexpic">
              <img :src="item.indexpic" alt />
            </div>
          </div>
          <div class="card-bottom clearfix">
            <span class="sales-num">{{ item.total_sales_num }}人做题</span>
            <span class="f-left price free" v-if="item.is_free > 0">免费</span>
            <span class="f-left price" v-else>
              <Currency class="currency"></Currency>
              {{ item.price / 100 }}</span
            >
          </div>
        </div>
      </el-card>
      <div
        class="empty-card"
        v-for="item in cols - (exercises.length % cols)"
        :key="exercises.length + item"
      ></div>
    </div>
    <el-pagination
      background
      @current-change="onCurrentChange"
      :current-page="page"
      :page-size="limit"
      layout="prev, pager, next, total, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import Classify from "../../components/Classify.vue";

export default {
  name: "exercise",
  components: { Classify },
  data() {
    return {
      classify_id: 0,
      attrs: [],
      attrCondition: {},
      exercises: [],
      cols: 3, // 列数
      page: 1,
      limit: 12,
      total: 0,
    };
  },
  created() {
    this.requestAttr();
    this.requestData();

    this.classify_id = this.$route.query.classify_id;
  },
  methods: {
    // on event
    onClickAttr(attrID, valueID) {
      this.$set(this.attrCondition, attrID, valueID);
      this.page = 1;
      this.requestData();
    },
    onCurrentChange(val) {
      this.page = val;
      this.requestData();
    },
    onClickTeacher(item) {
      this.$router.push({
        name: "ExerciseShow",
        params: {
          id: item.id,
        },
      });
    },
    // other
    // request
    requestAttr() {
      this.$http.get("/api/app/attribute/4").then((data) => {
        data.forEach((i) => {
          i.value &&
            i.value.unshift({
              id: 0,
              name: "全部",
            });
          this.attrCondition[i.id] = 0;
        });
        this.attrs = data;
      });
    },
    requestData() {
      const ary = [];
      for (const key in this.attrCondition) {
        if (this.attrCondition[key]) {
          ary.push(this.attrCondition[key]);
        }
      }
      let params = {
        classify_id: this.subClassify || this.classify_id,
        page: this.page,
        limit: this.limit,
        attr_val_id: ary.join(),
      };
      this.$http.get("/api/app/exercises", params).then((data) => {
        this.total = data.total;
        this.exercises = data.list;
      });
    },
    async changeClassify(flag, item) {
      let id = item.id;
      if (flag == 1) {
        this.classify_id = item.id;
        this.subClassify = null;
        this.$refs.classify.showChildClassify = true;
        this.$nextTick(() => {
          this.$refs.classify.childClassify = item.child;
        });
      } else {
        this.subClassify = item.id;
      }
      this.page = 1;
      await this.requestData();
    },
  },
};
</script>

<style lang="scss" scoped>
.select-tab {
  .item .left {
    width: 90px;
  }
}
.content {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .card {
    width: 390px;
    height: 239px;
    margin-bottom: 20px;
    cursor: pointer;
    /deep/ .el-card__body {
      height: 100%;
      box-sizing: border-box;
      padding: 0;
    }
    .card-content {
      text-align: center;
      position: relative;
      height: 100%;

      .card-top {
        height: 180px;
        border-bottom: 1px solid #f1f1f1;
        display: flex;
        flex: 1 0 auto;
      }
      .indexpic img {
        width: 133px;
        height: 133px;
        padding: 20px;
      }
      .exercise-info {
        padding: 20px;
        width: 192px;
        text-align: left;
        .name {
          font-size: 18px;
          font-weight: 500;
          color: #3e3a39;
        }
      }
      .card-bottom {
        line-height: 60px;
        .sales-num {
          float: left;
          padding-left: 17px;
          color: #666666;
          font-size: 12px;
        }
        .price {
          float: right;
          padding-right: 18px;
          font-size: 20px;
          font-weight: 500;
          color: #f1000e;
          &.free {
            color: #65ae58;
          }
        }
      }
      .student-free {
        border: 1px solid #008afd;
        color: #3b90ff;
        border-radius: 4px;
        display: inline-block;
        font-size: 12px;
        width: 64px;
        height: 24px;
        line-height: 25px;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
  .empty-card {
    width: 390px;
  }
}
.currency {
  width: 14px;
}
.bread {
  background-color: transparent;
  padding: 0;
  margin: 0;
  height: 62px;
  line-height: 62px;
  font-size: 14px;
  span {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  a {
    color: #333333 !important;
  }
}
.select-tab {
  margin-top: 0;
}
</style>

<template>
  <div class="login-wrap">
    <a class="lnk-login" target="_blank" href="/teacher/">
      <span>讲师登录</span>
    </a>
    <div class="form-box" @click.stop>
      <div class="center title">{{ $store.state.webConfig.web_title }}</div>
      <el-form
        v-if="!firstLogin && !otherLogin && !findPassword"
        :model="loginInfo"
        :rules="rules"
        ref="loginInfo"
        :hide-required-asterisk="true"
        :validate-on-rule-change="false"
      >
        <el-form-item prop="phone" class="phone">
          <el-input
            placeholder="请输入手机号"
            v-model="loginInfo.phone"
          ></el-input>
          <el-button
            :disabled="codeBtn"
            type="text"
            v-if="codeLogin"
            @click="get_code('loginInfo', 'login', loginInfo.phone)"
            >{{ codeBtnInfo }}</el-button
          >
        </el-form-item>
        <el-form-item prop="code" v-if="codeLogin" class="code">
          <el-input
            placeholder="请输入验证码"
            v-model="loginInfo.code"
            @keydown.native.enter="submitForm()"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          :class="!firstLogin ? 'password' : ''"
          v-if="!codeLogin"
        >
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="loginInfo.password"
            @keydown.native.enter="submitForm()"
          ></el-input>
        </el-form-item>
        <el-form-item class="forget">
          <div class="clear">
            <el-checkbox
              v-if="!codeLogin"
              class="f-left remember-me"
              v-model="isRememberMe"
            >
              记住我
            </el-checkbox>

            <el-button
              type="text"
              @click="forget_password"
              class="forget-btn f-left"
              v-if="!codeLogin"
            >
              忘记密码
            </el-button>
            <span v-else class="tip f-left">*未注册的手机号将自动注册</span>
            <el-button
              type="text"
              class="type-change f-right"
              @click="set_login_type"
              >{{ codeLogin ? "密码登录" : "注册/验证码登录" }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item class="submit">
          <div style="flex: 1">
            <div class="btn-login" @click="submitForm()">登录</div>
            <div class="clear contract">
              <el-checkbox
                v-model="acceptContract"
                class="f-left"
              ></el-checkbox>
              <span>
                <span>我同意</span>
                <a
                  href="javascript:void(0)"
                  @click="openContract('user_register_contract')"
                >
                  用户注册协议
                </a>
                <span>和</span>
                <a
                  href="javascript:void(0)"
                  @click="openContract('user_protect_contract')"
                >
                  隐私权保护政策
                </a>
              </span>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="firstLogin"
        :model="passInfo"
        :rules="passRules"
        ref="passInfo"
        :hide-required-asterisk="true"
        class="set-password"
        :validate-on-rule-change="false"
      >
        <el-form-item prop="password" :class="!firstLogin ? 'password' : ''">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="passInfo.password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="rePassword">
          <el-input
            type="password"
            placeholder="请再次输入密码"
            v-model="passInfo.rePassword"
          ></el-input>
        </el-form-item>
        <el-form-item class="set-pass">
          <div class="btn-login" @click="set_password">确定</div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="otherLogin"
        :model="otherLoginInfo"
        :rules="otherLoginRules"
        ref="otherLoginInfo"
        class="find-password"
        :hide-required-asterisk="true"
        :validate-on-rule-change="false"
      >
        <el-form-item prop="phone" class="phone">
          <el-input
            placeholder="请输入手机号"
            v-model="otherLoginInfo.phone"
          ></el-input>
          <el-button
            :disabled="codeBtn"
            type="text"
            @click="
              get_code('otherLoginInfo', 'loginOauth', otherLoginInfo.phone)
            "
            >{{ codeBtnInfo }}</el-button
          >
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            placeholder="请输入验证码"
            v-model="otherLoginInfo.code"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            type="password"
            v-model="otherLoginInfo.password"
          ></el-input>
        </el-form-item>
        <el-form-item class="submit">
          <div class="btn-login" @click="otherSubmitForm()">确定</div>
        </el-form-item>
      </el-form>
      <el-form
        v-show="findPassword"
        :model="otherLoginInfo"
        :rules="otherLoginRules"
        ref="findPasswordInfo"
        :hide-required-asterisk="true"
        class="find-password"
        :validate-on-rule-change="false"
      >
        <el-form-item prop="phone" class="phone">
          <el-input
            placeholder="请输入手机号"
            v-model="otherLoginInfo.phone"
          ></el-input>
          <el-button
            :disabled="codeBtn"
            type="text"
            @click="
              get_code('findPasswordInfo', 'getPassword', otherLoginInfo.phone)
            "
            >{{ codeBtnInfo }}</el-button
          >
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            placeholder="请输入验证码"
            v-model="otherLoginInfo.code"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            type="password"
            v-model="otherLoginInfo.password"
          ></el-input>
        </el-form-item>
        <el-form-item class="submit">
          <div class="btn-login" @click="findSubmitForm()">确定</div>
        </el-form-item>
      </el-form>
      <div class="other" v-if="!firstLogin && !findPassword && !otherLogin">
        <div class="title">
          <div class="line"></div>
          <span>其他方式登录</span>
          <div class="line"></div>
        </div>
        <div class="set clear">
          <div class="f-left" @click="qq_login()">
            <button type="button">
              <img class="qq" src="~@/assets/images/icon_login_qq@2x.png" />
            </button>
            <div>QQ登录</div>
          </div>
          <div class="f-right" @click="wx_login()">
            <button type="button">
              <img
                class="wechat"
                src="~@/assets/images/icon_login_wechat@2x.png"
              />
            </button>
            <div>微信登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storageKeys, saveData, loadData, removeData } from "@/utils/storage";

export default {
  name: "StudentLogin",
  data() {
    let validatePhone = (rule, value, callback) => {
      let reg = /^1\d{10}$/;
      if (value === "") {
        callback(new Error("请输入正确的手机号码"));
      } else if (reg.test(value) === false) {
        callback(new Error("请输入正确的11位号码！"));
      } else {
        callback();
      }
    };
    let validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    let validateRePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (this.passInfo.rePassword !== this.passInfo.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      isRememberMe: false,
      codeBtn: false,
      codeBtnInfo: "获取验证码",
      codeLogin: false,
      firstLogin: false,
      otherLogin: false,
      findPassword: false,
      loginInfo: {
        phone: "",
        password: "",
        code: "",
      },
      rules: {
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 16,
            message: "密码长度在 6 到 16 个字符",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            validator: validateCode,
            trigger: "blur",
          },
        ],
      },
      passInfo: {
        password: "",
        rePassword: "",
      },
      passRules: {
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 16,
            message: "密码长度在 6 到 16 个字符",
            trigger: "blur",
          },
        ],
        rePassword: [
          {
            required: true,
            validator: validateRePass,
            trigger: "blur",
          },
        ],
      },
      otherLoginInfo: {
        phone: "",
        code: "",
        password: "",
      },
      otherLoginRules: {
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            validator: validateCode,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 16,
            message: "密码长度在 6 到 16 个字符",
            trigger: "blur",
          },
        ],
      },
      openId: "",
      otherType: 1,
      acceptContract: true,
    };
  },
  async created() {
    if (this.$route.query.code) {
      this.otherType = this.$route.query.state;
      let data = await this.$http.post("/api/app/login", {
        type: this.otherType,
        code: this.$route.query.code,
      });
      if (data.id) {
        this.$store.commit("login", data.remember_token);
        this.$store.dispatch("get_user_info");
        this.goTo();
      } else {
        this.openId = data.msg;
        this.otherLogin = true;
      }
    }

    const account = loadData(storageKeys.account);
    const password = loadData(storageKeys.password);
    const isRememberMe = loadData(storageKeys.isRememberMe);
    if (isRememberMe) {
      this.loginInfo.phone = account;
      this.loginInfo.password = password;
      this.isRememberMe = true;
    }
  },
  mounted: function () {
    if (this.$route.query.type == "register") {
      this.set_login_type();
    }
    this.$nextTick(() => {
      document.getElementById("app").style.height = "100%";
    });
  },
  methods: {
    goTo() {
      if (this.$route.query.relogin) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    submitForm() {
      this.$refs["loginInfo"].validate(async (valid) => {
        if (valid) {
          await this.login();
        }
      });
    },
    set_login_type() {
      this.codeLogin = !this.codeLogin;
      this.resetForm("loginInfo");
      this.loginInfo.password = "";
      this.loginInfo.code = "";
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async login() {
      if (!this.acceptContract) {
        this.$info("您必须同意用户注册协议和隐私权保护政策", {
          duration: 2000,
        });
        return;
      }
      let request = {
        mobile: this.loginInfo.phone,
        type: this.codeLogin ? 2 : 1,
        client: 1,
      };
      if (this.codeLogin) {
        request.sms_code = this.loginInfo.code;
      } else {
        request.password = this.loginInfo.password;
      }
      let data = await this.$http.post("/api/app/login", request);
      if (data.is_new === 1 && this.codeLogin) {
        sessionStorage.setItem("pr_userId", data.id);
        sessionStorage.setItem("pr_webToken", data.remember_token);
        this.firstLogin = true;
      } else {
        if (this.isRememberMe) {
          saveData(storageKeys.account, this.loginInfo.phone);
          saveData(storageKeys.password, this.loginInfo.password);
          saveData(storageKeys.isRememberMe, this.isRememberMe);
        } else {
          removeData(storageKeys.account);
          removeData(storageKeys.password);
          removeData(storageKeys.isRememberMe);
        }
        this.$store.commit("login", data.remember_token);
        this.$store.dispatch("get_user_info");
        await this.$success("登录成功");
        localStorage.removeItem("start_time");
        this.goTo();
      }
    },
    get_code(info, type, mobile) {
      this.$refs[info].validateField("phone", async (validMessage) => {
        if (validMessage != "") {
          return;
        }
        await this.$http.post("/api/app/smsCode", {
          mobile: mobile,
          sms_type: type,
        });
        let count = 60;
        this.codeBtn = true;
        this.$message({
          message: "验证码已成功发送！",
          type: "success",
        });
        let sendTimer = setInterval(() => {
          count--;
          this.codeBtnInfo = "重新发送" + count;
          if (count < 0) {
            this.codeBtn = false;
            clearInterval(sendTimer);
            this.codeBtnInfo = "获取验证码";
          }
        }, 1000);
      });
    },
    set_password() {
      this.$refs["passInfo"].validate(async (valid) => {
        if (!valid) {
          return;
        }
        await this.$http.post("/api/app/password", {
          mobile: this.loginInfo.phone,
          password: this.passInfo.password,
          sms_code: this.loginInfo.code,
        });
        await this.$success("密码设置成功");
        this.$store.commit("login", sessionStorage.pr_webToken);
        this.$store.dispatch("get_user_info");
        this.goTo();
      });
    },
    forget_password() {
      this.findPassword = true;
      this.resetForm("findPasswordInfo");
    },
    qq_login() {
      let url = location.protocol + "//" + location.host;
      window.open(
        "https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=101783087&redirect_uri=" +
          url +
          "/login&state=3",
        "_blank"
      );
    },
    wx_login() {
      let url = location.protocol + "//" + location.host;
      window.open(
        "https://open.weixin.qq.com/connect/qrconnect?appid=wx3c7d636004029e33&redirect_uri=" +
          url +
          "/login&response_type=code&scope=snsapi_login&state=4#wechat_redirect",
        "_blank"
      );
    },
    otherSubmitForm() {
      const that = this;
      this.$refs["otherLoginInfo"].validate(async (valid) => {
        if (!valid) {
          return;
        }
        let data = await that.$http.post("/api/app/login", {
          type: that.$route.query.state,
          mobile: that.otherLoginInfo.phone,
          sms_code: that.otherLoginInfo.code,
          password: that.otherLoginInfo.password,
          openid: this.openId,
        });
        that.$store.commit("login", data.remember_token);
        that.$store.dispatch("get_user_info");
        await that.$success("登录成功");
        that.goTo();
      });
    },
    findSubmitForm() {
      this.$refs["findPasswordInfo"].validate(async (valid) => {
        if (!valid) {
          return;
        }
        await this.$http.post("/api/app/password", {
          mobile: this.otherLoginInfo.phone,
          password: this.otherLoginInfo.password,
          sms_code: this.otherLoginInfo.code,
        });
        await this.$info("密码重置成功，请登陆！");
        this.findPassword = false;
      });
    },
    openContract(key) {
      const { href } = this.$router.resolve({
        name: "Contract",
        query: {
          key: key,
        },
      });
      window.open(href);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/varibles.scss";

.login-wrap {
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: center;
  position: relative;
  .lnk-login {
    font-size: 18px;
    color: $primary-color;
    position: absolute;
    top: 32px;
    right: 31px;
    img {
      margin-right: 5px;
    }
    img,
    span {
      vertical-align: middle;
    }
  }
}

.form-box {
  width: 547px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  .contract {
    padding-top: 6px;
    color: #8c8c8c;
    a {
      color: $primary-color;
    }
    .el-checkbox {
      margin-right: 5px;
    }
  }
  .el-icon-error {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  /deep/ .el-form-item {
    margin-bottom: 0;
  }
  padding: 0 100px;
  box-sizing: border-box;
  .title {
    font-size: 24px;
    font-weight: 600;
    margin: 124px 0 84px 0;
    color: $primary-color;
  }
  /deep/ .el-form-item__content {
    margin-bottom: 16px;
    .el-input__inner {
      background-color: transparent !important;
      background: transparent !important;
    }
  }
}

.form-box h4 {
  padding: 50px 0;
  text-align: center;
  margin: 0;
  font-size: 26px;
  font-weight: bold;
  color: rgba(89, 89, 89, 1);
}

.form-box /deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 16px;
  color: #333;
}

.form-box /deep/ button {
  font-weight: normal;
}

.btn-login {
  height: 49px;
  text-align: center;
  line-height: 49px;
  color: white;
  cursor: default;
  background: $primary-color;
  box-shadow: 0px 9px 15px 1px rgba(62, 99, 255, 0.16);
  border-radius: 5px;
  flex: 1;
  margin-top: 12px;
}

.phone button {
  position: absolute;
  right: 15px;
  top: 12px;
  padding: 0;
}

.phone /deep/ button span {
  font-size: 16px;
  color: $primary-color;
}

.code,
.password,
.forget {
  margin-bottom: 10px;
}

.remember-me {
  margin-right: 1rem;
  padding-top: 0.2rem;
  display: flex;
  align-items: center;
}

.forget .tips {
  font-size: 12px;
  color: #999;
}

.forget /deep/ .el-form-item__content {
  overflow: hidden;
}

.forget /deep/ .el-form-item__content::before,
.forget /deep/ .el-form-item__content::after {
  display: none;
}

.forget {
  /deep/ button span,
  .tip {
    font-size: 14px;
    color: $primary-color;
    padding: 4px 0;
    line-height: 1;
  }
  /deep/ button {
    padding: 4px 0;
    span {
      padding: 0;
    }
  }
}

.forget /deep/ .forget-btn {
  float: left;
}

.forget /deep/ .type-change {
  float: right;
  span {
    color: #999;
  }
}

.submit .el-button {
  padding: 20px 0;
  color: #8c8c8c;
  font-size: 14px;
}

.set-pass {
  margin-top: 30px;
  border: none !important;
  .btn-login {
    flex: 1;
    margin-top: 70px;
  }
}

.set-pass button {
  height: 50px;
  width: 100%;
  background-color: #f4f4f4;
  border: none;
}

.set-pass button /deep/ span {
  font-size: 16px;
  color: #333;
}

.set-pass .confirm {
  background-color: $primary-color;
}

.set-pass .confirm /deep/ span {
  color: #fff;
}

.other .title {
  text-align: center;
  font-size: 14px;
  color: #b7b7b7;
  margin-bottom: 26px;
  margin-top: 0;
  display: flex;
  align-items: center;
  margin-top: 36px;
  .line {
    flex: 1;
    height: 1px;
    background: rgba(227, 227, 227, 1);
  }
  span {
    margin: 0 5px;
  }
}

.other .set {
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
  padding: 0 100px;
  img {
    width: 36px;
  }
  button {
    margin: 0 8px;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    background-color: #fff;
    vertical-align: middle;
    padding: 0;
    margin-bottom: 12px;
  }
  span {
    vertical-align: middle;
    color: #b7b7b7;
    font-size: 14px;
  }
}

/deep/ .header {
  position: static;
}
</style>

<template>
  <div class="login">
    <div class="logo">
      <router-link :to="{ name: 'Home' }">
        <img :src="$store.state.webConfig.web_logo" />
      </router-link>
    </div>
    <div class="login-main">
      <StudentLogin></StudentLogin>
      <div class="back"></div>
    </div>
  </div>
</template>
<script>
import StudentLogin from "../components/Login.vue";

export default {
  name: "Login",
  components: {
    StudentLogin,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.login {
  width: 1383px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /deep/ .footerBar {
    background-color: transparent;
    font-size: 18px;
    .link li,
    .link a {
      border-right-color: #333;
      color: #333333;
    }
  }
}
.logo {
  border-bottom: 4px solid $primary-color;
  img {
    max-height: 41px;
    margin: 22px 0 10px 0;
  }
}
.login-main {
  background-color: white;
  padding-bottom: 169px;
  position: relative;
  /deep/ .login {
    background-color: white;
  }
  .back {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 104px;
    background-image: url(../images/login/back.png);
    background-position: center 0;
    background-repeat: no-repeat;
  }
}
@mixin auto($percent) {
  .login {
    width: 1383 * $percent + px;
  }
}
@media screen and (max-width: 1600px) {
  @include auto(0.8333333333333333);
}
@media screen and (max-width: 1440px) {
  @include auto(0.75);
}
@media screen and (max-width: 1366px) {
  @include auto(0.7114583333333333);
}
@media screen and (max-width: 1280px) {
  @include auto(0.6666666666666667‬);
}
</style>

<template>
  <div class="login">
    <Header></Header>
    <div class="form-box" :style="{backgroundImage:'url('+loginBg+')'}">
      <el-form :model="loginInfo" :rules="rules" ref="loginInfo" :hide-required-asterisk="true">
        <h4>找回密码</h4>
        <el-form-item prop="phone" class="phone">
          <el-input type="text" placeholder="请输入手机号" v-model="loginInfo.phone"></el-input>
          <el-button :disabled="codeBtn" type="text" @click="get_code">{{codeBtnInfo}}</el-button>
        </el-form-item>
        <el-form-item prop="code" class="code">
          <el-input
            readonly
            onfocus="this.removeAttribute('readonly')"
            placeholder="请输入验证码"
            v-model="loginInfo.code"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" class="password">
          <el-input
            readonly
            onfocus="this.removeAttribute('readonly')"
            type="password"
            placeholder="请输入密码"
            v-model="loginInfo.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item class="submit">
          <div
            class="btn-login"
            :style="{backgroundImage:'url('+btnLogin+')'}"
            @click="submitForm('loginInfo')"
          >确定</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "ForgotPassword",
  components: {
    Header
  },
  data() {
    let validatePhone = (rule, value, callback) => {
      let reg = /^1\d{10}$/;
      if (value === "") {
        callback(new Error("请输入正确的手机号码"));
      } else if (reg.test(value) === false) {
        callback(new Error("请输入正确的11位号码！"));
      } else {
        callback();
      }
    };
    let validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      loginBg: require("@/assets/images/login_bg.png"),
      btnLogin: require("@/assets/images/btn-login.png"),
      codeBtn: false,
      codeBtnInfo: "获取验证码",
      loginInfo: {
        phone: "",
        password: "",
        code: ""
      },
      rules: {
        phone: [
          {
            validator: validatePhone,
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          },
          {
            min: 6,
            max: 16,
            message: "密码长度在 6 到 16 个字符",
            trigger: "blur"
          }
        ],
        code: [
          {
            validator: validateCode,
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      document.documentElement.style.height = "100%";
      document.body.style.height = "100%";
      document.getElementById("app").style.height = "100%";
    });
  },
  methods: {
    submitForm(loginInfo) {
      this.$refs[loginInfo].validate(async valid => {
        if (!valid) {
          return;
        }
        await this.$http.post("/api/app/password", {
          mobile: this.loginInfo.phone,
          password: this.loginInfo.password,
          sms_code: this.loginInfo.code
        });
        this.$message({
          message: "密码重置成功，请登陆！",
          type: "success",
          duration: 1500,
          onClose: () => {
            this.$router.push({
              name: "Login"
            });
          }
        });
      });
    },
    get_code() {
      const that = this;
      this.$refs["loginInfo"].validateField("phone", async validMessage => {
        if (validMessage != "") {
          return;
        }
        await this.$http.post(
          "/api/app/smsCode",
          {
            mobile: that.loginInfo.phone,
            sms_type: "getPassword"
          },
          false
        );
        let count = 60;
        that.codeBtn = true;
        that.$message({
          message: "验证码已成功发送！",
          type: "success"
        });
        let sendTimer = setInterval(function() {
          count--;
          that.codeBtnInfo = "重新发送" + count;
          if (count < 0) {
            that.codeBtn = false;
            clearInterval(sendTimer);
            that.codeBtnInfo = "获取验证码";
          }
        }, 1000);
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/varibles.scss";

.login {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/deep/ .header {
  position: static;
}

.form-box {
  margin: 0 auto;
  height: 100%;
  background-color: #fff;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
}

.form-box form {
  margin: 0 auto;
  padding: 0 130px;
  width: 595px;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding-top: 70px;
}

.form-box h4 {
  margin-bottom: 50px;
  text-align: center;
  color: $primary-color;
  margin-top: 0;
  font-size: 26px;
  font-weight: 500;
  color: rgba(89, 89, 89, 1);
}

.form-box /deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: 50px;
  line-height: 48px;
  font-size: 16px;
  color: #333;
}

.form-box /deep/ button {
  font-weight: normal;
}

.btn-login {
  width: 577px;
  height: 121px;
  text-align: center;
  line-height: 100px;
  color: white;
  cursor: default;
}

.phone /deep/ .el-input__inner {
  padding: 0 90px 0 15px;
}

.phone button {
  position: absolute;
  right: 15px;
  top: 0;
  height: 50px;
}

.phone /deep/ button span {
  text-decoration: underline;
  font-size: 16px;
  color: $primary-color;
}

.submit button {
  width: 100%;
  background-color: $primary-color;
}

.login /deep/ .el-button--primary {
  border-color: $primary-color;
}
</style>

<template>
  <div>
    <Header></Header>
    <div class="main-container">
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
export default {
  name: "Contract",
  components: {
    Header
  },
  data() {
    return {
      key: "",
      content: ""
    };
  },
  async created() {
    this.key = this.$route.query.key;
    let data = await this.$http.get("/api/contract/get?params=" + this.key);
    this.content = data[this.key];
  }
};
</script>
<style lang="scss" scoped>
.main-container {
  background-color: white;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 114px 20px 0 20px;
  .title {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
  }
}
</style>